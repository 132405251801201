import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import GridItem from '../../components/Grid/GridItem';

export const BulkUpdateAlert = props => {
  const { show, onClose, onConfirm, ...rest } = props;
  const [bulkData, setBulkData] = useState({ page_type: 'current' });
  const [errorText, setErrorText] = useState('');

  const handleInputChange = event => {
    setErrorText('');
    const name = event.target.name;
    const value = event.target.value;
    setBulkData(v => ({ ...v, [name]: value || undefined }));
  };

  // Function to handle negative values
  const handleNegative = e => {
    const characterCode = e.key;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const handleConfirm = () => {
    if (Object.entries(bulkData).length < 2) {
      setErrorText(
        'Please provide values for Bid Amount, Max Bid, Min Bid or Auto Bid.'
      );
    } else if (
      bulkData.reload === 'on' &&
      (!bulkData.max_quantity || bulkData.max_quantity < 2)
    ) {
      setErrorText(
        'Max quantity should be greater than 2, if not, reload option is not necessary.'
      );
    } else {
      onConfirm({...bulkData, reload: undefined});
    }
  };

  if (!show) return null;

  return (
    <SweetAlert
      style={{ display: 'block', marginTop: '-100px' }}
      title="Bulk Update"
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmBtnText="Submit"
      dependencies={[bulkData]}
      type="controlled"
      {...rest}
    >
      <div className="text-muted lead">
        <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <h6 className="h6ClassToShow">{errorText}</h6>
          <div className="row">
            <GridItem xs={12} sm={12} md={12}>
              <table className="col-md-12">
                <tbody>
                  <tr>
                    <td>Bid Amount</td>
                    <td>
                      <input
                        type="number"
                        id="float"
                        onKeyPress={e => handleNegative(e)}
                        name="auto_bid"
                        min={0}
                        value={bulkData.auto_bid}
                        onChange={event => handleInputChange(event)}
                        style={{ width: '50%' }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Max Bid Amount</td>
                    <td>
                      {' '}
                      <input
                        type="number"
                        id="float"
                        onKeyPress={e => handleNegative(e)}
                        name="max_bid"
                        min={0}
                        value={bulkData.max_bid}
                        onChange={event => handleInputChange(event)}
                        style={{ width: '50%' }}
                      />{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Min Bid Amount</td>
                    <td>
                      {' '}
                      <input
                        type="number"
                        id="float"
                        onKeyPress={e => handleNegative(e)}
                        name="min_bid"
                        min={0}
                        value={bulkData.min_bid}
                        onChange={event => handleInputChange(event)}
                        style={{ width: '50%' }}
                      />{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Auto Bid</td>
                    <td>
                      {' '}
                      <div className="cu-checkbox text-left">
                        <input
                          type="radio"
                          style={{ width: '20px', height: '15px' }}
                          id="activeBid"
                          name="auto_bid_status"
                          value="True"
                          checked={bulkData.auto_bid_status === 'True'}
                          onChange={handleInputChange}
                          title="Active"
                        />
                        <label for="active" title="Active">
                          Active
                        </label>
                        <input
                          type="radio"
                          style={{
                            width: '20px',
                            height: '15px',
                            marginLeft: '20px'
                          }}
                          id="inactiveBid"
                          name="auto_bid_status"
                          value="False"
                          checked={bulkData.auto_bid_status === 'False'}
                          onChange={handleInputChange}
                          title="Inactive"
                        />
                        <label for="inactive" title="Inactive">
                          Inactive
                        </label>
                      </div>
                    </td>
                  </tr>
                  {bulkData.auto_bid_status === 'True' && (
                    <tr>
                      <td />
                      <td>
                        {' '}
                        <div className="cu-checkbox text-left">
                          <input
                            type="radio"
                            style={{
                              width: '20px',
                              height: '15px'
                            }}
                            id="reload"
                            name="reload"
                            min={1}
                            checked={bulkData.reload === 'on'}
                            onChange={handleInputChange}
                            title="Reload"
                          />
                          <label for="reload" title="Reload">
                            Reload
                          </label>
                        </div>
                      </td>
                    </tr>
                  )}
                  {bulkData.reload === 'on' && (
                    <tr>
                      <td>Max quantity</td>
                      <td>
                        {' '}
                        <div className="cu-checkbox text-left">
                          <input
                            type="number"
                            id="maxQuantity"
                            name="max_quantity"
                            value={bulkData.max_quantity}
                            onChange={handleInputChange}
                            onKeyPress={e => handleNegative(e)}
                            style={{ width: '50%' }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Apply For</td>
                    <td>
                      {' '}
                      <div className="cu-checkbox text-left">
                        <input
                          type="radio"
                          style={{ width: '20px', height: '15px' }}
                          id="currentPageCheck"
                          name="page_type"
                          value="current"
                          checked={bulkData.page_type === 'current'}
                          onChange={handleInputChange}
                          title="Current"
                        />
                        <label
                          for="currentPageCheck"
                          title="Current"
                        >
                          Current
                        </label>
                        <input
                          type="radio"
                          style={{
                            width: '20px',
                            height: '15px',
                            marginLeft: '20px'
                          }}
                          id="allPageCheck"
                          name="page_type"
                          value="all"
                          checked={bulkData.page_type === 'all'}
                          onChange={handleInputChange}
                          title="All"
                        />
                        <label for="allPageCheck" title="All">
                          All
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </GridItem>
          </div>
        </GridItem>
      </div>
    </SweetAlert>
  );
};
