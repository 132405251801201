import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
// import * as Sentry from "@sentry/browser";
import NumberFormat from "react-number-format";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// core components
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardIcon from "../../components/Card/CardIcon.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import Permission from "../../permission/Permission.jsx";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import {
  STATISTICS_API_URL,
  LAST_SYNC_API_URL
} from "../../configurations/configApi.jsx";
import sandbox from "../../assets/img/sandbox.png";
import SweetAlert from "react-bootstrap-sweetalert";
import "rc-time-picker/assets/index.css";



class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      activeBids: "",
      pendingCount: "",
      historyCount: "",
      expiresToday: "",
      parentProductCount: "",
      childProductCount: "",
      autoBidCount: "",
      totalAutoBidWinCount: "",
      syncCeleryRun: [],
      highestCount: "",
      lowestCount: "",
      parentCreated: "",
      parentUpdated: "",
      childCreated: "",
      childUpdated: "",
      sync: "",
      placed: "",
      outOfSync: "",
      loading: false,
      goatParents: "",
      goatVariants: "",
      sweetAlert: "",
      open: false,
      syncLoading: false,
      goatCreated: "",
      goatUpdated: "",
      variantCreated: "",
      variantUpdated: "",
      lastSyncLoading: false,
      valueOfAuto: "",
      lastSyncTime: "",
      globalUpdate: {},
      bulkDeleteUpdate: {},
      bulkBidUpdate: {},
      dbConnectionCount:"",
      tooltipOpen:false,
      scrapyStatus:{},
      goatScrapy:{},
      brandToScrap:[],
      categoriesToScrap:[],
    };
    this.fetchStatistics = this.fetchStatistics.bind(this);
  }

  // function to check whether error is due to 500 or 404 status code
  checkFunction = error => {
    if (
      (error.response &&
        error.response.status &&
        (error.response.status === 404 || error.response.status === 500)) ||
      !error.response
    ) {
      return true;
    }
  };

  // function to show the Error Alert message
  fetchAlert(response) {
    const sweet = (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={response ? "" : "Error"}
        onConfirm={() => this.setState({ sweetAlert: "" })}
        confirmBtnText="OK"
      >
        <h4>
          {response
            ? response.message
            : "Error due to 404 or 500 response.Please Try again later :("}
        </h4>
        {response && <ErrorOutlineIcon className="errorOutlineIcon" />}
      </SweetAlert>
    );
    this.setState({ sweetAlert: sweet });
  }

  // function to show the dashboard statistics
  fetchStatistics() {
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    this.setState({ loading: true });

    axios
      .get(STATISTICS_API_URL, {
        method: "get",
        headers
      })
      .then(response => {
        this.setState({
          activeBids: response.data.total_bid_active,
          pendingCount: response.data.total_pending,
          historyCount: response.data.total_history,
          expiresToday: response.data.total_bid_expires_today,
          parentProductCount: response.data.total_parent_product,
          childProductCount: response.data.total_child_product,
          totalAutoBidWinCount: response.data.total_auto_bid_win_count,
          totalAutoHistoryCount: response.data.total_auto_bid_win_count_history,
          syncCeleryRun: response.data.celery_run,
          highestCount: response.data.latest_products.stockx.highest_bid,
          lowestCount: response.data.latest_products.stockx.lowest_ask,
          sync: response.data.auto_bid_last_sync_after_two_hrs,
          placed: response.data.auto_bid_placed_within_two_hrs,
          outOfSync: response.data.auto_bid_last_sync_before_two_hrs,
          autoBidCount: response.data.total_auto_bid_count,
          parentCreated:
            response.data.latest_products.stockx.parent_products_created,
          parentUpdated:
            response.data.latest_products.stockx.parent_products_updated,
          childCreated:
            response.data.latest_products.stockx.child_products_created,
          childUpdated:response.data.latest_products.stockx.child_products_updated,
          goatParents: response.data.goat_products.parent_products,
          goatVariants: response.data.goat_products.variant_products,
          goatCreated:
            response.data.latest_products.goat.parent_products_created,
          goatUpdated:
            response.data.latest_products.goat.parent_products_updated,
          variantCreated:
            response.data.latest_products.goat.variant_products_created,
          variantUpdated:
            response.data.latest_products.goat.variant_products_updated,
          loading: false,
          globalUpdate:response.data.global_update_status,
          bulkDeleteUpdate:response.data.bulk_delete_status,
          bulkBidUpdate:response.data.bulk_bid_update_status,
          dbConnectionCount:response.data.db_connection_count,
          scrapyStatus:response.data.stockx_scrapy,
          goatScrapy:response.data.goat_scrapy,
          brandToScrap:response.data.stockx_brands_to_scrap,
          categoriesToScrap:response.data.stockx_categories_to_scrap,
        });
        
        if (this.state.valueOfAuto || this.state.lastSyncTime) {
          this.setState({ open: true });
          this.state.valueOfAuto && this.onTimeChange(this.state.valueOfAuto);
          this.state.lastSyncTime &&
            this.handleTimeChange(this.state.lastSyncTime);
        }
      })
      .catch(error => {
        // Sentry.captureException("Dashboard API " + error);
        if (this.checkFunction(error)) {
          this.setState({ loading: false });
          this.fetchAlert();
        } else {
          this.setState({ loading: true });
        }
      });
  }

  componentDidMount() {
    this.fetchStatistics();

  }

  // function to open the Time Panel on Auto Bid Last section
  setOpen = ({ open }) => {
    this.setState({ open });
  };

  // function to change the time in input and call api on Auto Bid Last section
  onTimeChange = value => {
    this.setState({
      open: !this.state.open,
      syncLoading: true,
      valueOfAuto: value
    });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    axios(STATISTICS_API_URL, {
      method: "post",
      headers,
      data: { hour: value ? value.hour() : 2 }
    })
      .then(response => {

        this.setState({
          syncLoading: false,
          sync: response.data.auto_bid_last_sync_after_two_hrs,
          placed: response.data.auto_bid_placed_within_two_hrs,
          outOfSync: response.data.auto_bid_last_sync_before_two_hrs,
          autoBidCount: response.data.total_auto_bid_count
        });
      })
      .catch(error => {
        // Sentry.captureException("Dashboard Time POST API " + error);
        if (this.checkFunction(error)) {
          this.setState({ syncLoading: false });
          this.fetchAlert();
        } else {
          this.fetchAlert(error.response.data);
          this.setState({ syncLoading: false });
        }
      });
  };

  // Function to post Last Sync Api on dynamic Hours
  handleTimeChange = hour => {
    this.setState({ lastSyncLoading: true, lastSyncTime: hour });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    axios(`${LAST_SYNC_API_URL}`, {
      method: "post",
      headers,
      data: { timeInHours: hour ? hour : 6 }
    })
      .then(response => {

        this.setState({
          lastSyncLoading: false,
          highestCount: response.data.stockx.highest_bid,
          lowestCount: response.data.stockx.lowest_ask,
          parentCreated: response.data.stockx.parent_products_created,
          parentUpdated: response.data.stockx.parent_products_updated,
          childCreated: response.data.stockx.child_products_created,
          childUpdated: response.data.stockx.child_products_updated,
          goatCreated: response.data.goat.parent_products_created,
          goatUpdated: response.data.goat.parent_products_updated,
          variantCreated: response.data.goat.variant_products_created,
          variantUpdated: response.data.goat.variant_products_updated,

        });
      })
      .catch(error => {
        // Sentry.captureException("Dashboard Last Sync Time POST API " + error);
        if (this.checkFunction(error)) {
          this.setState({ lastSyncLoading: false });
          this.fetchAlert();
        } else {
          this.fetchAlert(error.response.data);
          this.setState({ lastSyncLoading: false });
        }
      });
  };




  // Function to avoid Negative Values
  handleNegatives = e => {
    const characterCode = e.key;

    // when enter key is clicked, it will call time change api
    if (e.which === 13) {
      this.handleTimeChange(e.target.value);
    }
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  render() {

    const { classes } = this.props;
    const {
      activeBids,
      pendingCount,
      historyCount,
      expiresToday,
      parentProductCount,
      childProductCount,
      autoBidCount,
      syncCeleryRun,
      highestCount,
      lowestCount,
      totalAutoBidWinCount,
      parentCreated,
      parentUpdated,
      childCreated,
      childUpdated,
      goatCreated,
      goatUpdated,
      variantCreated,
      variantUpdated,
      sync,
      placed,
      outOfSync,
      loading,
      goatParents,
      goatVariants,
      sweetAlert,
      open,
      syncLoading,
      lastSyncLoading,
      globalUpdate,
      bulkDeleteUpdate,
      bulkBidUpdate,
      dbConnectionCount,
      scrapyStatus,
      goatScrapy,
      categoriesToScrap,
    } = this.state;


    const formatted = param =>
      syncCeleryRun.map(each => {
        return each[param] && each[param];
      });

    const blinkIcon = param =>
      syncCeleryRun.filter(each => {
        return each[param] && each[param];
      });

    const blinkGlobal = (param) => {
      return globalUpdate[param];
    };

    const blinkBulk = (objectValue, param) => {
      return objectValue[param];
    };

    const blinkPercentage = param => {
      return Math.sign(globalUpdate[param]) === 1 ?
        <><span className="material-icons upPercent">arrow_upward</span></> :
        Math.sign(globalUpdate[param]) === -1 ? <><span className="material-icons downPercent">
          arrow_downward
      </span></> : ""
    }

    const time = param =>
      syncCeleryRun.map(each => {
        return each["auto_bid"] && each[param];
      });





    return (
      <div className="dashboard">
        <Permission />
        {sweetAlert}
        <span className="reload" title="Reload">
          <Icon onClick={() => this.fetchStatistics()} title="Reload">
            refresh
          </Icon>
        </span>
        <GridContainer>
          <h2>Bids</h2>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>thumbs_up_down</Icon>
                </CardIcon>

                {blinkIcon("sync_stockx_buying_products_formatted")[0] &&
                  blinkIcon("sync_stockx_buying_products_formatted")[0].active ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Active
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Active
                    </p>
                  )}

                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={activeBids}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {syncCeleryRun[1] && (
                    <div className="updateTime">
                      <span>
                        <Icon
                          className={
                            blinkIcon("sync_stockx_buying_products_formatted")[0] &&
                              blinkIcon("sync_stockx_buying_products_formatted")[0].active
                              ? "rotate"
                              : ""
                          }
                        >
                          cached
                        </Icon>

                        {formatted("sync_stockx_buying_products_formatted")}
                        {blinkIcon("sync_stockx_buying_products_formatted")[0] &&
                          blinkIcon("sync_stockx_buying_products_formatted")[0].active
                          ? ` (${blinkIcon("sync_stockx_buying_products_formatted")[0]
                            .products_updated
                          })`
                          : ""}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Icon>hourglass_empty</Icon>
                </CardIcon>
                {blinkIcon("sync_stockx_pending_products_formatted")[0] &&
                  blinkIcon("sync_stockx_pending_products_formatted")[0].active ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Pending
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Pending
                    </p>
                  )}
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={pendingCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {syncCeleryRun[1] && (
                    <div className="updateTime">
                      <span>
                        <Icon
                          className={
                            blinkIcon("sync_stockx_pending_products_formatted")[0] &&
                              blinkIcon("sync_stockx_pending_products_formatted")[0].active
                              ? "rotate"
                              : ""
                          }
                        >
                          cached
                        </Icon>
                        {formatted("sync_stockx_pending_products_formatted")}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                  <Icon>history</Icon>
                </CardIcon>
                {blinkIcon("sync_stockx_order_history_formatted")[0] &&
                  blinkIcon("sync_stockx_order_history_formatted")[0].active ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> History
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> History
                    </p>
                  )}
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={historyCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {syncCeleryRun[1] && (
                    <div className="updateTime">
                      <span>
                        <Icon
                          className={
                            blinkIcon("sync_stockx_order_history_formatted")[0] &&
                              blinkIcon("sync_stockx_order_history_formatted")[0].active
                              ? "rotate"
                              : ""
                          }
                        >
                          cached
                        </Icon>
                        {formatted("sync_stockx_order_history_formatted")}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                  <Icon>toggle_on</Icon>
                </CardIcon>
                {blinkIcon("auto_bid_formatted")[0] &&
                  blinkIcon("auto_bid_formatted")[0].active ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Auto Enabled
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Auto Enabled
                    </p>
                  )}
                {totalAutoBidWinCount ? (
                  <span class="autobided" title="Auto Bid Win Count">
                    <span class="material-icons">star</span>
                    {totalAutoBidWinCount}
                  </span>
                ) : (
                    ""
                  )}
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={autoBidCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {syncCeleryRun[1] && (
                    <div className="updateTime">
                      <span>
                        <Icon
                          className={
                            blinkIcon("auto_bid_formatted")[0] &&
                              blinkIcon("auto_bid_formatted")[0].active
                              ? "rotate"
                              : ""
                          }
                        >
                          cached
                        </Icon>
                        {formatted("auto_bid_formatted")}
                        {blinkIcon("auto_bid_formatted")[0] &&
                          blinkIcon("auto_bid_formatted")[0].active
                          ? ` (${blinkIcon("auto_bid_formatted")[0]
                            .products_updated
                          })`
                          :  blinkIcon("auto_bid_formatted")[0] ? ` (${blinkIcon("auto_bid_formatted")[0]
                            .product_synced_in_last_run
                          })`: ''}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card className="addTimer">
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>sync_alt</Icon>
                </CardIcon>
                {syncLoading && (
                  <div className="gridLoader">
                    <Icon className="rotate">cached</Icon>
                  </div>
                )}
                <p className={classes.cardCategory} title="Auto">
                  <span>Auto Bid Last </span>
                  <TimePicker
                    open={open}
                    onOpen={this.setOpen}
                    onClose={this.setOpen}
                    showMinute={false}
                    showSecond={false}
                    placeholder="2"
                    onChange={value => this.onTimeChange(value)}
                  />
                  <span>hrs</span>
                </p>
                <h6 className={classes.cardTitle}>
                  <span>Sync : {sync} </span>
                  <span>Placed : {placed} </span>
                  <span>Out Of Sync : {outOfSync}</span>
                </h6>
              </CardHeader>
              <CardFooter stats className="c-footer">
                <div className="updateTime">
                  <span>Elapsed Time: {time("elapsed_time")}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>timer</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Expires Today</p>
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={expiresToday}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                  <Icon>public</Icon>
                </CardIcon>
                {Object.keys(globalUpdate).length > 0 &&
                  blinkGlobal("active") ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Global Update (DB)
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Global Update (DB)
                    </p>
                  )}
                <h3 className={classes.cardTitle}>
                  {
                    <NumberFormat
                    value={globalUpdate.total_products ? globalUpdate.total_products : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  }
                </h3>
                <span className="percentaged" title="Status of Global Update">
                  {blinkPercentage("percentage_value")}
                  {blinkGlobal("percentage_value")}%</span>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {Object.keys(globalUpdate).length > 0 && (
                    <div className="updateTime">
                      <span>
                        {blinkGlobal("status") === "Queued" ?
                          <img style={{ height: "13px", marginTop: "0px", marginRight: "3px" }} src={sandbox} alt="" /> :
                          (blinkGlobal("ended_on") ||
                            blinkGlobal("started_on")) && <Icon
                              className={
                                blinkGlobal("active")
                                  ? "rotate"
                                  : ""
                              }>cached</Icon>}
                        {blinkGlobal("status") === "Completed" ?
                          blinkGlobal("ended_on") :
                          blinkGlobal("status") === "Queued" ?
                            blinkGlobal("global_update_queued") :
                            blinkGlobal("started_on")}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card className="addTimer">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>update</Icon>
                </CardIcon>
                {Object.keys(bulkBidUpdate).length > 0 &&
                  blinkBulk(bulkBidUpdate, "active") ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Bulk Bid Update
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Bulk Bid Update
                    </p>
                  )}
                <h4 className={classes.cardTitle}>
                  {blinkBulk(bulkBidUpdate, "status") === "Queued" ? "" : <NumberFormat
                    value={bulkBidUpdate.total_products ? bulkBidUpdate.total_products : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />}
                </h4>

                <h5 className={classes.cardTitle}>
                  <span>{blinkBulk(bulkBidUpdate, "auto_bid_amount") &&
                  <><i>Bid :</i> {blinkBulk(bulkBidUpdate, "auto_bid_amount")} </>} &nbsp;&nbsp;&nbsp;
                  {blinkBulk(bulkBidUpdate, "max_bid_amount") &&
                  <><i>Max Bid :</i> {blinkBulk(bulkBidUpdate, "max_bid_amount")}</> }    </span>
                  <span>{blinkBulk(bulkBidUpdate, "auto_bid_status")&&
                  <><i>Auto Bid :</i> {blinkBulk(bulkBidUpdate, "auto_bid_status") === "True"?"Active":"InActive"}</> }
                 </span>
                  <span> <i>Page Type :</i> {blinkBulk(bulkBidUpdate, "page_type")}</span>
                  {blinkBulk(bulkBidUpdate, "status")==="InProgress"&& <span>
                  <><i>Products Updated :</i> {blinkBulk(bulkBidUpdate, "products_updated")} </>
                 </span>}


                </h5>
              </CardHeader>
              <CardFooter stats className="c-footer">
                <div className={classes.stats}>
                  {Object.keys(bulkBidUpdate).length > 0 &&

                  (
                    <div className="updateTime">
                        <span  >
                         {blinkBulk(bulkBidUpdate, "status") === "Queued" ?
                          <Icon title={"Queued"}>dynamic_feed</Icon> :
                           blinkBulk(bulkBidUpdate, "status") === "Completed" ?
                           <Icon  title={"Completed"}>check_circle</Icon> :
                           blinkBulk(bulkBidUpdate, "status") === "InProgress" ?
                           <Icon title={"InProgress"}>cached</Icon> :
                           blinkBulk(bulkBidUpdate, "status") === "Undoing" ?
                           <Icon title={"Undoing"}>hourglass_empty</Icon> :
                           blinkBulk(bulkBidUpdate, "status") === "Reverted" ?
                          <Icon title={"Reverted"}>keyboard_return</Icon> :
                           blinkBulk(bulkBidUpdate, "status") === "Cancelled" ?
                           <Icon title={"Cancelled"}>cancel</Icon>
                          :""
                          }
                           {blinkBulk(bulkBidUpdate, "status") === "Cancelled" || blinkBulk(bulkBidUpdate, "status") === "Queued" ?
                            (blinkBulk(bulkBidUpdate, "created_on")) :
                           blinkBulk(bulkBidUpdate, "status") === "Completed" ?
                            blinkBulk(bulkBidUpdate, "ended_on"):
                           blinkBulk(bulkBidUpdate, "started_on")
                          }
                       </span>
                     </div>
                  )
                  }
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>delete</Icon>
                </CardIcon>
                {Object.keys(bulkDeleteUpdate).length > 0 &&
                  blinkBulk(bulkDeleteUpdate, "active") ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i /> Bulk Delete Update
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i /> Bulk Delete Update
                    </p>
                  )}
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={bulkDeleteUpdate.total_products ? bulkDeleteUpdate.total_products : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  {/* {blinkBulk(bulkDeleteUpdate, "status") === "Queued" ? "" : <NumberFormat
                    value={bulkDeleteUpdate.total_products ? bulkDeleteUpdate.total_products : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />} */}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  {Object.keys(bulkDeleteUpdate).length > 0 && (
                    <div className="updateTime">
                      <span>
                        {blinkBulk(bulkDeleteUpdate, "status") === "Queued" ?
                          <img style={{ height: "13px", marginTop: "0px", marginRight: "3px" }} src={sandbox} alt=""/> :
                          (blinkBulk(bulkDeleteUpdate, "ended_on") ||
                            blinkBulk(bulkDeleteUpdate, "started_on")) && <Icon
                              className={
                                blinkBulk(bulkDeleteUpdate, "active")
                                  ? "rotate"
                                  : ""
                              }>cached</Icon>}
                        {blinkBulk(bulkDeleteUpdate, "status") === "Completed" ?
                          blinkBulk(bulkDeleteUpdate, "ended_on") :
                          blinkBulk(bulkDeleteUpdate, "status") === "Queued" ?
                            blinkBulk(bulkDeleteUpdate, "bulk_update_queued") :
                            blinkBulk(bulkDeleteUpdate, "started_on")}
                      </span>
                    </div>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Icon>save_alt</Icon>
                </CardIcon>
                {Object.keys(scrapyStatus).length > 0 &&
                  blinkBulk(scrapyStatus, "is_running")===true ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i />Stockx  Scrapy
                    </p>
                  ) : (
                    <p className={classes.cardCategory}>
                      <i />Stockx  Scrapy
                    </p>
                  )}

               <h5 className={classes.cardTitle}>
               <span>{blinkBulk(scrapyStatus, "category") &&
                  <><i>Category : </i> {blinkBulk(scrapyStatus, "category")}</> }
                </span>
                <br/>
                 <span>
                 {blinkBulk(scrapyStatus, "brand") &&
                  <><i>Brand :</i> {blinkBulk(scrapyStatus, "brand")}</> }
                 </span>
                </h5>

              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  {Object.keys(scrapyStatus).length > 0 &&
                    <div className="updateTime">
                        <span>
                        <Icon className={blinkBulk(goatScrapy, "is_running") === true ? "rotate":""}>cached</Icon>
                        {blinkBulk(scrapyStatus, "updated_on") &&
                      <>{blinkBulk(scrapyStatus, "updated_on")}</>}
                       </span>
                     </div>
                  }
                </div>

              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>save_alt</Icon>
                </CardIcon>
                {Object.keys(goatScrapy).length > 0 &&
                  blinkBulk(goatScrapy, "is_running")===true ? (
                    <p className={classes.cardCategory} data-attr="run">
                      <i />Goat Scrapy
                    </p>
                  ) : (
                    <p className={classes.cardCategory} >
                      <i />Goat  Scrapy
                    </p>
                  )}

                <h5 className={classes.cardTitle}>
                    <span>{blinkBulk(goatScrapy, "category") &&
                  <><i>Category : </i> {blinkBulk(goatScrapy, "category")}</>
                 }</span>
                  <br/>
                  <span>
                  {blinkBulk(goatScrapy, "brand") &&
                  <><i>Brand :</i> {blinkBulk(goatScrapy, "brand")}</> }</span>
                </h5>

              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  {Object.keys(goatScrapy).length > 0 &&
                    <div className="updateTime">
                        <span>
                        <Icon className={blinkBulk(goatScrapy, "is_running") ===true ? "rotate":""}>cached</Icon>
                        {blinkBulk(goatScrapy, "updated_on") &&
                      <>{blinkBulk(goatScrapy, "updated_on")}</>}
                       </span>
                     </div>
                  }
                </div>

              </CardFooter>
            </Card>
          </GridItem>


          <h2>Products</h2>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>shopping_cart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Parent</p>
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={parentProductCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>add_shopping_cart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Child</p>
                <h3 className={classes.cardTitle}>
                  <NumberFormat
                    value={childProductCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>shopping_basket</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Goat</p>
                <h6 className={classes.cardTitle}>
                  <span>Parent : {goatParents} </span>
                  <span>Variant : {goatVariants} </span>
                </h6>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Icon>cached</Icon>
                </CardIcon>

                <h4 className="card-title last-sys">
                  Last Sync (In
                  <input
                    type="number"
                    min={0}
                    onKeyDown={this.handleNegatives}
                    placeholder="6"
                  />
                  hours)
                </h4>
              </CardHeader>
              <CardContent>
                {lastSyncLoading && (
                  <div className="gridLoader last-syns">
                    <Icon className="rotate">cached</Icon>
                  </div>
                )}
                <div className="table-responsive table-sales">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td colSpan="3">
                          <h3>Stockx</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            keyboard_capslock
                          </span>{" "}
                          Highest Bid
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={highestCount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            text_rotate_vertical
                          </span>
                          Lowest Ask
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={lowestCount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            playlist_add
                          </span>
                          Parent Created
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={parentCreated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            playlist_add_check
                          </span>{" "}
                          Parent Updated{" "}
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={parentUpdated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            library_add
                          </span>{" "}
                          Child Created{" "}
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={childCreated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            library_add_check
                          </span>{" "}
                          Child Updated
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={childUpdated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>

                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            dns
                          </span>{" "}
                          DB Connection
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={dbConnectionCount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>

                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            copy_all
                          </span>{" "}
                          Categories To Scrap
                        </td>
                        <td className="text-right w-50">
                        <div className="BrandScroll">
                            {
                              categoriesToScrap.map((res,index)=>{
                                if(index !== categoriesToScrap.length-1){
                                  return <span key={res} >{res}, </span>
                                }else{
                                  return <span key={res} >{res} </span>
                                }
                              })
                            }
                        </div>
                        </td>
                        <td className="text-right" />
                      </tr>

                      {/* <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            content_copy
                          </span>{" "}
                          Brand To Scrap
                        </td>
                        <td className="text-right w-50">
                        <div className="BrandScroll">
                            {
                              brandToScrap.map((res)=>{
                                return <span key={res} >{res}, </span>
                              })
                            }
                        </div>
                        </td>
                        <td className="text-right" />
                      </tr>
                      */}

                      <tr>
                        <td colSpan="3">
                          <h3>Goat</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            add_shopping_cart_icon
                          </span>
                          Goat Parent Created
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={goatCreated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            shopping_cart_icon
                          </span>{" "}
                          Goat Parent Updated{" "}
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={goatUpdated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            shop_icon
                          </span>{" "}
                          Goat Variant Created{" "}
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={variantCreated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                      <tr>
                        <td>
                          <span
                            _ngcontent-lmj-c19=""
                            className="material-icons icon-image-preview"
                          >
                            shop_two_icon
                          </span>{" "}
                          Goat Variant Updated
                        </td>
                        <td className="text-right">
                          <NumberFormat
                            value={variantUpdated}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                        <td className="text-right" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
        <LoadingOverlay showOverlay={loading} />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
